<template>
    <div class="extend">
        <div class="form-box">
            <el-form
                style="flex:1;margin-top:20px;margin-left: 40px;"
                label-position="left"
                label-width="100px"
            >
                <el-form-item
                    label="到期时间"
                    required
                    style="margin-left:-10px"
                >
                    <el-date-picker
                        v-model="aliReportExpireTime"
                        size="small"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width:270px;margin-left:10px"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-radio-group v-model="expireTime" size="small">
                        <el-radio-button
                            label="30天"
                            @click.native.prevent="clickTime('30天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="60天"
                            @click.native.prevent="clickTime('60天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="90天"
                            @click.native.prevent="clickTime('90天')"
                        ></el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { aliReportEdit } from '@/api/user/business/customer';
export default {
    data() {
        return {
            data: {},
            aliReportExpireTime: '',
            expireTime: '30天',
        };
    },
    methods: {
        getData(row) {
            this.data = row;
            let date = new Date();
            this.aliReportExpireTime =
                this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
        },
        // 到期时间点击
        clickTime(e) {
            // console.log(e);
            e === this.expireTime
                ? (this.expireTime = '')
                : (this.expireTime = e);
            let date = new Date();
            if (e == this.expireTime && e == '30天') {
                this.aliReportExpireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            } else if (e == this.expireTime && e == '60天') {
                this.aliReportExpireTime =
                    this.$searchTime.getNextDate(date, -60) + ' 00:00:00';
            } else if (e == this.expireTime && e == '90天') {
                this.aliReportExpireTime =
                    this.$searchTime.getNextDate(date, -90) + ' 00:00:00';
            } else {
                this.aliReportExpireTime = '';
            }
        },
        onSubmit() {
            let data = {
                param: {
                    customerId: this.data.customerId,
                    customerAliReportDO: {
                        id: this.data.id,
                        aliReportExpireTime: this.aliReportExpireTime,
                    },
                },
            };
            aliReportEdit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                }
                this.onClose();
            });
        },
        onClose() {
            this.expireTime = '30天';
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.extend {
    width: 100%;

    .form-box {
        height: 280px;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
