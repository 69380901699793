<template>
  <div class="extend">
    <div class="form-box">
      <el-form
        style="flex:1;margin-top:20px;margin-left: 40px;"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="公司名称">
          <span class="text">{{ data.companyName }}</span>
          <span
            @click="copyText(data.companyName)"
            style="margin-left:20px;color:#2370EB;cursor: pointer;"
            >复制</span
          >
        </el-form-item>
        <el-form-item
          label="审核备注"
          v-if="auditStatus == 1"
          required
          style="margin-left:-10px"
        >
          <el-input
            type="textarea"
            :rows="2"
            style="width:270px;margin-left:10px"
            placeholder="请输入内容"
            v-model="auditRemark"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="auditStatus == 2"
          label="驳回原因"
          required
          style="margin-left:-10px"
        >
          <el-input
            type="textarea"
            :rows="2"
            style="width:270px;margin-left:10px"
            placeholder="请输入内容"
            v-model="rejectReason"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { aliAudit } from "@/api/user/business/customer";
export default {
  data() {
    return {
      auditStatus: "1",
      rejectReason: "",
      data: "",
      auditRemark: "",
    };
  },
  methods: {
    getData(row) {
      this.data = row;
    },
    onSubmit() {
      let data = {
        param: {
          customerId: this.data.customerId,
          customerAliReportDO: {
            id: this.data.id,
            auditStatus: this.auditStatus,
          },
        },
      };
      if (this.auditStatus == 1) {
        data.param.customerAliReportDO.auditRemark = this.auditRemark;
      } else {
        data.param.customerAliReportDO.rejectReason = this.rejectReason;
      }
      aliAudit(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("审核成功");
        }
        this.onClose();
      });
    },
    onClose() {
      this.rejectReason = "";
      this.auditStatus = "1";
      this.$emit("close");
    }, //复制
    copyText(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
      });
      oInput.remove();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.extend {
  width: 100%;

  .form-box {
    height: 360px;
  }
  .but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 72px;
    }
  }
}
</style>
