<template>
    <div class="customer">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>筛选字段</span>
                    <el-select
                        v-model="field"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="公司名称" value="1"></el-option>
                        <el-option label="域名" value="4"></el-option>
                        <el-option label="审核备注" value="5"></el-option>
                    </el-select>

                    <el-input
                        size="small"
                        style="width: 150px; margin-left: 16px"
                        placeholder="请输入内容"
                        v-model.trim="fieldVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>申请时间</span>
                    <el-radio-group v-model="followTime" size="small">
                        <el-radio-button
                            label="0-3天"
                            @click.native.prevent="clickFollow('0-3天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="3-10天"
                            @click.native.prevent="clickFollow('3-10天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="10天外"
                            @click.native.prevent="clickFollow('10天外')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="followDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px; margin-left: 19px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                    <el-button
                        v-show="fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right: 29px; color: #2162cb"
                    >
                        收起 <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button
                        v-show="!fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right: 29px; color: #2162cb"
                    >
                        展开 <i class="el-icon-arrow-down"></i>
                    </el-button>
                </p>
            </div>
            <div class="search-two" v-show="fold">
                <span>报备状态</span>
                <el-radio-group v-model="aliReportStatusVal" size="small">
                    <el-radio-button
                        label="申请中"
                        @click.native.prevent="onReportStatus('申请中')"
                    ></el-radio-button>
                    <el-radio-button
                        label="已报备"
                        @click.native.prevent="onReportStatus('已报备')"
                    ></el-radio-button>
                    <el-radio-button
                        label="驳回"
                        @click.native.prevent="onReportStatus('驳回')"
                    ></el-radio-button>
                    <el-radio-button
                        label="已过期"
                        @click.native.prevent="onReportStatus('已过期')"
                    ></el-radio-button>
                </el-radio-group>
                <span>成员</span>
                <el-select
                    v-model="adminIdVal"
                    size="small"
                    style="width: 180px"
                    clearable
                    placeholder="请选择"
                    @change="getTableData(), getReportBrief()"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span>报备库</span>
                <el-select
                    v-model="libraryCategory"
                    placeholder="请选择"
                    size="small"
                    style="width: 180px"
                    clearable
                    @change="getTableData()"
                >
                    <el-option label="伽搜" value="1"></el-option>
                    <el-option label="云梦" value="2"></el-option>
                    <el-option label="华中" value="3"></el-option>
                    <el-option label="山总" value="4"></el-option>
                    <el-option label="畅搜" value="5"></el-option>
                </el-select>
            </div>
        </div>
        <div class="table-title">
            <p>我的客户列表</p>
            <div v-if="aliReportStatusVal == '已报备'">
                <p
                    :class="isColor == 1 ? 'color' : ''"
                    @click="getTableData(1)"
                >
                    <span>全部</span>
                    <span style="float: right">{{
                        statistics.total ? statistics.total : '0'
                    }}</span>
                </p>
                <p
                    :class="isColor == 2 ? 'color' : ''"
                    @click="getTableData(2)"
                >
                    <i class="iconfont icon-biaoqian"></i> <span>今日</span>
                    <span style="color: #2370eb; float: right">{{
                        statistics.todayTotal ? statistics.todayTotal : '0'
                    }}</span>
                </p>
                <p
                    :class="isColor == 3 ? 'color' : ''"
                    @click="getTableData(3)"
                >
                    <span>本月</span>
                    <span style="float: right">{{
                        statistics.monthTotal ? statistics.monthTotal : '0'
                    }}</span>
                </p>
                <p
                    :class="isColor == 4 ? 'color' : ''"
                    @click="getTableData(4)"
                >
                    <i class="iconfont icon-biaoqian"></i>&lt;<span>15天</span>
                    <span style="color: #ff760f; float: right">{{
                        statistics.day15Total ? statistics.day15Total : '0'
                    }}</span>
                </p>
                <p
                    :class="isColor == 5 ? 'color' : ''"
                    @click="getTableData(5)"
                >
                    <i class="iconfont icon-biaoqian"></i>&lt;<span>7天</span>
                    <span style="color: #df5045; float: right">{{
                        statistics.day7Total ? statistics.day7Total : '0'
                    }}</span>
                </p>
            </div>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="公司名称" width="230" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!-- <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.companyName"
            placement="top"
            v-if="scope.row.companyName.length > 14"
          >
            <el-button
              type="text"
              style="color:#2370EB"
              @click="onDetails(scope.row)"
              >{{
                $tableDataHandle.subStr(14, scope.row.companyName)
              }}</el-button
            >
          </el-tooltip> -->
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onDetails(scope.row)"
                        >{{ scope.row.companyName }}</el-button
                    >
                </template>
            </el-table-column>

            <el-table-column label="域名" width="170" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="returnDomain(scope.row)"
                        placement="top"
                        v-if="returnDomain(scope.row).length > 16"
                    >
                        <div>
                            {{
                                $tableDataHandle.subStr(
                                    16,
                                    returnDomain(scope.row)
                                )
                            }}
                        </div>
                    </el-tooltip>
                    <div v-else>
                        {{
                            $tableDataHandle.subStr(16, returnDomain(scope.row))
                        }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="报备状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.aliReportStatus == 1
                            ? '未报备'
                            : scope.row.aliReportStatus == 2
                            ? '申请中'
                            : scope.row.aliReportStatus == 3
                            ? '已报备'
                            : scope.row.aliReportStatus == 4
                            ? '驳回'
                            : scope.row.aliReportStatus == 5
                            ? '已过期'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.aliReportApplyTime">{{
                        $tableDataHandle.subStr(
                            10,
                            scope.row.aliReportApplyTime
                        )
                    }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="报备到期时间"
                show-overflow-tooltip
                v-if="aliReportStatus != 4"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.aliReportExpireTime">{{
                        $tableDataHandle.subStr(
                            10,
                            scope.row.aliReportExpireTime
                        )
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="员工"
                prop="adminName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="报备库" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.libraryCategory == 1
                            ? '伽搜'
                            : scope.row.libraryCategory == 2
                            ? '云梦'
                            : scope.row.libraryCategory == 3
                            ? '华中'
                            : scope.row.libraryCategory == 4
                            ? '山总'
                            : scope.row.libraryCategory == 5
                            ? '畅搜'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="
                            aliReportStatusVal == '申请中' && btnP.reportaudit
                        "
                        style="color: #2370eb"
                        type="text"
                        @click="onReporting(scope.row)"
                        >审核</el-button
                    >
                    <el-button
                        v-if="aliReportStatusVal == '已报备' && btnP.upData"
                        style="color: #2370eb"
                        type="text"
                        @click="onEdit(scope.row)"
                        >修改</el-button
                    >
                    <el-button
                        v-if="aliReportStatusVal == '已报备' && btnP.upData"
                        style="color: #2370eb"
                        type="text"
                        @click="onActiveGiveReport(scope.row)"
                        >放弃</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 报备 -->
        <el-drawer
            :visible.sync="drawerReport"
            size="480px"
            :before-close="handleClose"
            :direction="direction"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    报备审核
                </div>
            </template>
            <Reporting @close="handleClose" ref="report" />
        </el-drawer>
        <!-- 延长报备 -->
        <el-dialog
            :visible.sync="drawerExtend"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    延长报备
                </div>
            </template>
            <Extend @close="handleClose" ref="extend" />
        </el-dialog>
        <!-- 放弃报备 -->
        <el-dialog
            :visible.sync="drawerGiveUp"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    放弃报备
                </div>
            </template>
            <GiveUp @close="handleClose" ref="giveup" />
        </el-dialog>
        <!-- 修改报备 -->
        <el-dialog
            :visible.sync="drawerEdit"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    修改报备
                </div>
            </template>
            <EditReport @close="handleClose" ref="edit" />
        </el-dialog>
        <!-- 主动放弃报备 -->
        <el-dialog
            :visible.sync="drawerActiveGive"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    主动放弃报备
                </div>
            </template>
            <ActiveGiveReport @close="handleClose" ref="activegivereport" />
        </el-dialog>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleDetails"
            size="1080px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    客户详情
                </div>
            </template>
            <Details @close="handleDetails" ref="details" />
        </el-drawer>
    </div>
</template>
<script>
import { aliReport } from '@/api/user/business/customer';
import { getCustomerId } from '@/api/user/ae/customer';
import { AddAssistList, aliReportBrief } from '@/api/user/ae/customer';
import Reporting from './reporting.vue';
import Details from '../../mailbox/aliReporting/details/details.vue';
import Extend from './extend.vue';
import GiveUp from './giveup.vue';
import EditReport from './active/editReport.vue';
import ActiveGiveReport from './active/giveReport.vue';
export default {
    components: {
        Reporting,
        Details,
        Extend,
        GiveUp,
        EditReport,
        ActiveGiveReport,
    },
    data() {
        return {
            fold: true,
            field: '1',
            fieldVal: '',
            adminIdVal: '',
            followTime: '',
            followDate: [],
            aliReportStatusVal: '申请中',
            aliReportStatus: 2,
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerReport: false,
            drawerExtend: false,
            drawerGiveUp: false,
            drawerEdit: false,
            drawerActiveGive: false,
            drawer: false,
            direction: 'rtl',
            options: [],
            statistics: {},
            isColor: 0,
            libraryCategory: '',
            btnP: {},
            isPage: false,
            pageData: {},
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.getBtn();

        if (this.$route.query.reportId) {
            let data = {
                param: {
                    id: this.$route.query.reportId,
                },
            };
            getCustomerId(data).then((res) => {
                this.onReporting(res.data);
                let path = this.$route.path; //先获取路由路径
                this.$router.push(path);
            });
        } else if (this.$route.query.trialId) {
            let data = {
                param: {
                    id: this.$route.query.trialId,
                },
            };
            getCustomerId(data).then((res) => {
                this.onTrial(res.data);
                let path = this.$route.path; //先获取路由路径
                this.$router.push(path);
            });
        }
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);

            this.getUserList();

            this.getTableData();

            this.getReportBrief();
        },
        getReportBrief() {
            let data = {
                param: {
                    adminId: this.adminIdVal,
                },
            };
            aliReportBrief(data).then((res) => {
                this.statistics = res.data;
            });
        },
        getUserList() {
            let data = {
                param: {
                    roleTypeList: [4, 8, 9, 10, 17],
                },
            };
            AddAssistList(data).then((res) => {
                this.options = res.data;
            });
        },
        getTableData(i) {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (Number(i)) {
                this.field = '';
                this.fieldVal = '';
                this.followTime = '';
                this.followDate = '';
                this.libraryCategory = '';
                this.isColor = i;
                let date = new Date();
                data.param.adminId = this.adminIdVal;
                data.param.aliReportStatus = 3;
                if (i == 2) {
                    data.param.endCreateDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                } else if (i == 3) {
                    Date.prototype.format = function (fmt) {
                        var o = {
                            'M+': this.getMonth() + 1, //月份
                            'd+': this.getDate(), //日
                            'h+': this.getHours(), //小时
                            'm+': this.getMinutes(), //分
                            's+': this.getSeconds(), //秒
                            'q+': Math.floor((this.getMonth() + 3) / 3), //季度
                            S: this.getMilliseconds(), //毫秒
                        };
                        if (/(y+)/.test(fmt)) {
                            fmt = fmt.replace(
                                RegExp.$1,
                                (this.getFullYear() + '').substr(
                                    4 - RegExp.$1.length
                                )
                            );
                        }
                        for (var k in o) {
                            if (new RegExp('(' + k + ')').test(fmt)) {
                                fmt = fmt.replace(
                                    RegExp.$1,
                                    RegExp.$1.length == 1
                                        ? o[k]
                                        : ('00' + o[k]).substr(
                                              ('' + o[k]).length
                                          )
                                );
                            }
                        }
                        return fmt;
                    };
                    let date = new Date();
                    let firstDay = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        1
                    );

                    data.param.endCreateDate = date.format('yyyy-MM-dd');
                    data.param.startCreateDate = firstDay.format('yyyy-MM-dd');
                } else if (i == 4) {
                    data.param.endExpireDate = this.$searchTime.getNextDate(
                        date,
                        -15
                    );
                    data.param.startExpireDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                } else if (i == 5) {
                    data.param.endExpireDate = this.$searchTime.getNextDate(
                        date,
                        -7
                    );
                    data.param.startExpireDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                }
            } else {
                data.param.endExpireDate = '';
                data.param.startCreateDate = '';
                data.param.endCreateDate = '';
                data.param.startExpireDate = '';
                this.isColor = 0;
                if (this.fieldVal != '') {
                    if (this.field == 1) {
                        data.param.companyName = this.fieldVal;
                    } else if (this.field == 5) {
                        data.param.auditRemark = this.fieldVal;
                    } else if (this.field == 4) {
                        data.param.companyDomain = this.fieldVal;
                    }
                }
                if (this.followTime != '') {
                    let date = new Date();
                    this.followDate = [];
                    if (this.followTime == '0-3天') {
                        data.param.endCreateDate = this.$searchTime.getNextDate(
                            date,
                            0
                        );
                        data.param.startCreateDate =
                            this.$searchTime.getNextDate(date, 3);
                    } else if (this.followTime == '3-10天') {
                        data.param.endCreateDate = this.$searchTime.getNextDate(
                            date,
                            3
                        );
                        data.param.startCreateDate =
                            this.$searchTime.getNextDate(date, 10);
                    } else if (this.followTime == '10天外') {
                        data.param.outFollowDate = this.$searchTime.getNextDate(
                            date,
                            10
                        );
                    }
                }
                if (this.aliReportStatusVal != '') {
                    if (this.aliReportStatusVal == '申请中') {
                        data.param.aliReportStatus = 2;
                    } else if (this.aliReportStatusVal == '已报备') {
                        data.param.aliReportStatus = 3;
                    } else if (this.aliReportStatusVal == '驳回') {
                        data.param.aliReportStatus = 4;
                    } else if (this.aliReportStatusVal == '已过期') {
                        data.param.aliReportStatus = 5;
                    }
                }

                if (this.followDate && this.followDate.length > 0) {
                    data.param.startCreateDate = this.followDate[0];
                    data.param.endCreateDate = this.followDate[1];
                }
                if (this.adminIdVal != '') {
                    data.param.adminId = this.adminIdVal;
                }
                if (this.libraryCategory != '') {
                    data.param.libraryCategory = this.libraryCategory;
                }
            }
            this.pageData = data;
            aliReport(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
                this.aliReportStatus = data.param.aliReportStatus;
            });
        },
        // 跟进时间点击
        clickFollow(e) {
            e === this.followTime
                ? (this.followTime = '')
                : (this.followTime = e);
            this.getTableData();
        },
        // 跟进报备状态
        onReportStatus(e) {
            this.aliReportStatusVal = e;

            this.getTableData();
        },

        // 时间输入框变化
        expireTime() {
            if (this.followDate && this.followDate.length > 0) {
                this.followTime = '';
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 报备
        onReporting(row) {
            if (row.aliApplyStatus == 2 || row.aliApplyStatus == 8) {
                this.drawerReport = true;
                setTimeout(() => {
                    this.$refs.report.echoData(row);
                }, 50);
            } else if (row.aliApplyStatus == 3) {
                this.drawerExtend = true;
                setTimeout(() => {
                    this.$refs.extend.getData(row);
                }, 50);
            } else if (row.aliApplyStatus == 4) {
                this.drawerGiveUp = true;
                setTimeout(() => {
                    this.$refs.giveup.getData(row);
                }, 50);
            }
        },

        handleClose() {
            this.drawerReport = false;
            this.drawerTrial = false;
            this.drawerExtend = false;
            this.drawerGiveUp = false;
            this.drawerEdit = false;
            this.drawerActiveGive = false;
            this.getTableData();
        },
        // 修改
        onEdit(row) {
            this.drawerEdit = true;
            setTimeout(() => {
                this.$refs.edit.getData(row);
            }, 50);
        },
        // 放弃
        onActiveGiveReport(row) {
            this.drawerActiveGive = true;
            setTimeout(() => {
                this.$refs.activegivereport.getData(row);
            }, 50);
        },
        // 详情
        // onDetails(row) {
        //     this.drawer = true;
        //     let data = {
        //         param: {
        //             id: row.customerId,
        //         },
        //     };
        //     getCustomerId(data).then((res) => {
        //         let btn = JSON.parse(localStorage.getItem("isButArr"));
        //         var btnChild = [];
        //         for (let i in btn) {
        //             if (btn[i].route == 'details') {
        //                 btnChild = btn[i].children;
        //             }
        //         }
        //         this.drawerTitle = '客户详情';
        //         if (btnChild.length > 0) {
        //             this.drawer = true;
        //         }
        //         let btnP = {};
        //         for (let i in btnChild) {
        //             btnP['' + btnChild[i].route] = true;
        //         }
        //         setTimeout(() => {
        //             this.$refs.details.getData(res.data, btnP, false);
        //         }, 0);
        //         this.$formDetails.commit('detailsData', JSON.stringify(res.data));
        //         this.$choiceLabel.commit('getIsDetails', false);
        //         this.$choiceLabel.commit('getType', '2');
        //     });
        // },
        onDetails(row) {
            this.$router.push('/customerdetails');
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getType', '2');
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },
        handleDetails() {
            this.drawer = false;
            this.$formDetails.commit('emptyData');
            this.$choiceLabel.commit('emptyData');
            this.getTableData();
        },
        // 域名处理
        returnDomain(row) {
            // console.log(row);
            if (row.companyDomain) {
                return row.companyDomain;
            } else if (
                row.customerDomainList &&
                row.customerDomainList.length > 0
            ) {
                return row.customerDomainList[0].domain;
            } else {
                return '- -';
            }
        },
        // 重置
        searchEmpty() {
            this.field = '';
            this.fieldVal = '';
            this.followTime = '';
            this.followDate = '';
            this.aliReportStatusVal = '';
            this.aliTrialStatusVal = '';
            this.libraryCategory = '';
            this.$choiceLabel.commit('emptyData');
            this.getTableData();
        },
        beforeRouteLeave(to, form, next) {
            this.$choiceLabel.commit('emptyData');
            next();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 12px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        div {
            .color {
                border-color: #2370eb;
            }
            p {
                display: inline-block;
                border-radius: 4px;
                border: 1px solid #e5e5e5;
                padding: 5px 8px;
                min-width: 80px;
                position: relative;
                margin-left: 12px;
                cursor: pointer;
                i {
                    position: absolute;
                    top: 0;
                    left: -3px;
                    font-size: 10px;
                }
            }
            p:nth-child(2) {
                i {
                    color: #2370eb;
                }
            }
            p:nth-child(4) {
                i {
                    color: #ff760f;
                }
            }
            p:nth-child(5) {
                i {
                    color: #df5045;
                }
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
</style>
